<template>
    <main>
        <Form :model="search" :label-width="80">
            <Row>
                <Col span="4">
                    <FormItem label="用户名">
                        <Input v-model="search.username" placeholder="请输入用户名"></Input>
                    </FormItem>
                </Col>
                <Col span="4">
                    <FormItem label="账号">
                        <Input v-model="search.account" placeholder="请输入账号"></Input>
                    </FormItem>
                </Col>
                <Col span="14">
                    <Button type="primary" class="margin_left_10" @click.native="searchData">查询</Button>
                </Col>
            </Row>
        </Form>
        <Card :dis-hover="false">
            <div  class="margin_bottom_10">
                <ButtonGroup shape="circle">
                    <Button  @click.native="showAddRole">新增</Button>
                </ButtonGroup>
                <Divider type="vertical" />
                <ButtonGroup shape="circle">
                    <Button @click="downloadTemplate">下载模板</Button>
                    <Button @click="uploadFile">导入用户</Button>
                </ButtonGroup>
            </div>
            <Table :columns="columns" :data="data">
                <template slot-scope="{ row }" slot="status">
                    {{ row.isDefault == 1 ? "内置用户" : row.status == 1 ? "启用" : "禁用" }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <Button size="small" class="margin_right_10" v-if="row.isDefault == 0" @click.native="updateStatus(row)" :loading="updateStatusLoading">{{ row.status == 0 ? "启用" : "禁用" }}</Button>
                    <Button size="small" type="primary" @click.native="updateUser(row)" class="margin_right_10" v-if="row.isDefault == 0">修改</Button>
                    <Button size="small" type="primary" @click.native="resetPassword(row)" class="margin_right_10" v-if="row.isDefault == 0">重置密码</Button>
                    <Button size="small" type="primary" @click.native="updateUserRole(row)" class="margin_right_10" v-if="row.isDefault == 0">角色</Button>
                    <Button size="small" type="error" @click.native="delUser(row.uuid)" class="margin_right_10" v-if="row.isDefault == 0">删除</Button>
                </template>
            </Table>
            <Page class="margin_10" v-if="page.pages > 1" :total="page.total" :current="page.pageNum" @on-change="changePage" show-elevator show-sizer/>
        </Card>
        <AddUser :addUserShow="addUserShow"></AddUser>
        <UpdateUser :updateUserShow="updateUserShow" :userInfo="userInfo"></UpdateUser>
        <UserRole :updateUserRoleShow="updateUserRoleShow" :userInfo="userInfo"></UserRole>
        <ImportUser ref="importUserRef" @success="impostUserSuccess"></ImportUser>
    </main>
</template>

<script>
    import { saveAs as FileSaver } from 'file-saver';
    import { base64ToBlob } from "/src/lib/tools"
    import AddUser from "/src/framework/user/AddUser.vue";
    import UpdateUser from "/src/framework/user/UpdateUser.vue";
    import UserRole from "/src/framework/user/UserRole.vue";
    import ImportUser from "/src/framework/user/ImportUser.vue"
    export default {
        name: "User",
        components: {
            AddUser,
            UpdateUser,
            UserRole,
            ImportUser
        },
        data() {
            return {
                updateUserRoleShow: false,
                updateUserShow: false,
                userInfo: {},
                updateStatusLoading: false,
                addUserShow: false,
                search: {
                    username: "",
                    account: ""
                },
                page:{
                    pageNum: 1,
                    pageSize: 10,
                    total: 0,
                    pages: 1
                },
                columns:[
                    {
                        title: '用户名',
                        key: 'username'
                    },
                    {
                        title: '账号',
                        key: 'account'
                    },
                    {
                        title: '手机号',
                        key: 'phone'
                    },
                    {
                        title: '年龄',
                        key: 'age'
                    },
                    {
                        title: '职名',
                        key: 'post'
                    },
                    {
                        title: '状态',
                        slot: 'status'
                    },
                    {
                        title: '备注',
                        key: 'remark'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 350
                    }
                ],
                data:[]
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            searchData(){
                this.page.pageNum = 1
                this.loadData()
            },
            loadData(){
                let page = {
                    pageNum : this.page.pageNum - 1,
                    pageSize : this.page.pageSize
                }
                let _search = Object.assign({}, this.search, page);
                this.post("/permission/user/list", _search).then(value => {
                    this.data = value.data.data.data
                    this.page = {
                        total: value.data.data.total,
                        pageSize: value.data.data.pageSize,
                        pageNum: value.data.data.pageNum,
                        pages: value.data.data.pages
                    }
                }).catch(reason => {
                    console.log(reason);
                })
            },
            changePage(pageNum){
                this.page.pageNum = pageNum;
                this.loadData();
            },
            showAddRole() {
                this.addUserShow = true;
            },
            updateStatus(data){
                this.updateStatusLoading = true;
                let status = data.status == 1 ? 0 : 1;
                this.post("/permission/user/status/update", {uuid: data.uuid, status: status}).then(() => {
                    this.loadData();
                    this.$Message.info({
                        content: status == 1? '启用成功' : "禁用成功"
                    });
                    this.updateStatusLoading = false;
                }).catch(reason => {
                    console.log(reason);
                    this.$Message.info({
                        content: status == 1? '启用失败' : "禁用失败"
                    });
                    this.updateStatusLoading = false;
                })
            },
            delUser(uuid){
                this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.post("/permission/user/del", {uuid: uuid}).then(() => {
                        this.loadData();
                    }).catch(reason => {
                        console.log(reason);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除操作'
                    });
                });
            },
            updateUser(data){
                this.updateUserShow = true;
                this.userInfo = data;
            },
            updateUserRole(data){
                this.updateUserRoleShow = true;
                this.userInfo = data;
            },
            resetPassword(data){
                this.$confirm('此操作将重置该用户账号密码, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.post("/permission/user/password/reset", {uuid: data.uuid}).then((res) => {
                        this.$Message.info({
                            content: "重置成功，密码为" + res.data.data.password,
                            duration: 5
                        });
                        this.updateStatusLoading = false;
                    }).catch(reason => {
                        console.log(reason);
                        this.$Message.info({
                            content: "重置失败"
                        });
                        this.updateStatusLoading = false;
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消重置'
                    });
                });
            },
            downloadTemplate(){
                this.post('/permission/user/downloadTemplate', {}).then((res) => {
                    console.log(res)
                    let blob = base64ToBlob(res.data.data.content);
                    FileSaver.saveAs(blob, res.data.data.fileName)
                })
            },
            uploadFile(){
                this.$refs.importUserRef.show()
            },
            impostUserSuccess(){
                this.searchData()
            }
        }
    }
</script>

<style scoped>

</style>
