<template>
    <Modal :title="title" v-model="showModel" class-name="vertical-center-modal"
           @on-cancel="cancel" @on-ok="save" :loading="loading">
        <Upload
            type="drag"
            :format="['.xls']"
            :on-format-error="handleFormatError"
            :before-upload="handleBeforeUpload"
            :disabled="uploadDisabled"
            action="//jsonplaceholder.typicode.com/posts/">
            <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                <p>点击选择excel文件，上传用户</p>
            </div>
        </Upload>
        <div slot="footer">
            <Button @click="() => {showModel = false}">取消</Button>
        </div>
    </Modal>
</template>

<script>
    import { saveAs as FileSaver } from 'file-saver';
    import { blobToBase64, base64ToBlob } from "/src/lib/tools"
    export default {
        name: "ImportUser",
        components:{
        },
        data(){
            return {
                title:"上传用户",
                showModel: false,
                loading: false,
                uploadDisabled: false
            }
        },
        methods:{
            cancel() {
            },
            save(){
                this.loading = true
            },
            show(){
                this.showModel = true;
            },
            handleBeforeUpload (file) {
                if(file.name.lastIndexOf(".xls") == -1){
                    this.$Message.warning({
                        title: '文件格式不正确',
                        content: '文件 ' + file.name + ' 格式不正确，请选择 .xls 文件。'
                    })
                    return false;
                }
                this.$Message.loading({
                    content: '文件处理中，请稍后。。。',
                    duration: 0
                })
                this.uploadDisabled = true
                blobToBase64(file).then(fileBase => {
                    const fileBase64 = fileBase.split(';base64,')[1]
                    this.post('/permission/user/importUser', {
                        fileBase64: fileBase64
                    }).then(res => {
                        console.log(res)
                        this.uploadDisabled = false
                        this.$Message.destroy()
                        this.$Message.success({
                            content: '导入成功'
                        })
                        this.$emit("success")
                        this.showModel = false
                    }).catch((res) => {
                        this.uploadDisabled = false
                        this.$Message.destroy()
                        if(res.code == 400){
                            let blob = base64ToBlob(res.data.content);
                            FileSaver.saveAs(blob, res.data.fileName)
                            this.$Message.error({
                                content: '数据错误，请修改后重新导入'
                            })
                        }else {
                            this.$Message.error({
                                content: '导入失败'
                            })
                        }
                    })
                }).catch(() => {
                    this.uploadDisabled = false
                })
                return false
            },
            handleFormatError (file) {
                this.$Message.warning({
                    title: '文件格式不正确',
                    content: '文件 ' + file.name + ' 格式不正确，请选择 .xls 文件。'
                })
            }
        },
    }
</script>

<style scoped>
    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ivu-modal{
        top: 0;
    }
</style>