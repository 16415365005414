<template>
    <Modal :title="title" v-model="show" class-name="vertical-center-modal"
           @on-cancel="cancel" @on-ok="save" :loading="loading">
        <Form :model="formItem" :label-width="80">
            <FormItem label="用户名">
                <Input v-model="formItem.username" placeholder="请输入用户名"></Input>
            </FormItem>
            <FormItem label="账号">
                <Input v-model="formItem.account" placeholder="请输入账号"></Input>
            </FormItem>
            <FormItem label="手机号">
                <Input v-model="formItem.phone" placeholder="请输入手机号"></Input>
            </FormItem>
            <FormItem label="年龄">
                <Input type="number" v-model="formItem.age" placeholder="请输入年龄"></Input>
            </FormItem>
            <FormItem label="职名">
                <Input v-model="formItem.post" placeholder="请输入职名"></Input>
            </FormItem>
            <FormItem label="状态">
                <RadioGroup v-model="formItem.status">
                    <Radio label="1">启用</Radio>
                    <Radio label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="备注">
                <Input v-model="formItem.remark" placeholder="请输入备注"></Input>
            </FormItem>
            <FormItem label="组织机构">
                <SelectTree v-model="formItem.orgUuids" placeholder="请选择组织机构" :tree="orgTree"></SelectTree>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
    import SelectTree from '/src/components/SelectTree'
    import { mapState } from 'vuex';
    export default {
        name: "UpdateUser",
        components:{
            SelectTree
        },
        data(){
            return {
                title:"修改用户",
                formItem:{
                    username: "",
                    account: "",
                    phone: "",
                    status: "1",
                    remark: "",
                    orgUuids: [],
                    age: null,
                    post: ''
                },
                show: false,
                loading: false
            }
        },
        computed: {
            ...mapState("org", [
                "orgTree"
            ]),
        },
        props: {
            updateUserShow:{
                type: Boolean,
                default: true,
            },
            userInfo:{}
        },
        methods:{
            cancel() {
                this.$parent.updateUserShow = false
                this.$parent.userInfo = {};
            },
            save(){
                this.loading = true
                this.post("/permission/user/update", this.formItem).then(() => {
                    this.$Message.info({
                        content: '修改成功'
                    });
                    this.$parent.roleInfo = {};
                    this.$parent.loadData();
                    this.loading = false;
                    this.cancel()
                }).catch(() => {
                    this.loading = false
                })
            }
        },
        watch:{
            updateUserShow(){
                this.show = this.updateUserShow;
            },
            show(){
                if(!this.show){
                    this.formItem = this.$options.data().formItem
                    this.$parent.updateUserShow = this.show;
                }
            },
            userInfo(){
                if(this.userInfo.uuid){
                    this.post("/permission/user/get", {
                        uuid: this.userInfo.uuid
                    }).then(value => {
                        let data = value.data.data
                        Object.assign(this.formItem, data)
                        this.formItem.status = this.formItem.status + "";
                    }).catch(reason => {
                        console.log(reason);
                        this.$message.error("获取用户信息失败")
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ivu-modal{
        top: 0;
    }
</style>