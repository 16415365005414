<template>
    <Modal :title="title" v-model="show" class-name="vertical-center-modal"
           @on-cancel="cancel" @on-ok="save" :loading="loading">
        <Tree ref="tree" :data="roleInfo" show-checkbox expand-node></Tree>
    </Modal>
</template>

<script>
    export default {
        name: "UserRole",
        data() {
            return {
                title: "更新角色",
                show: false,
                loading:false,
                roleInfo: []
            }
        },
        props: {
            updateUserRoleShow:{
                type: Boolean,
                default: true,
            },
            userInfo:{}
        },
        created() {

        },
        methods:{
            cancel() {
                this.$parent.updateUserRoleShow = false
                this.$parent.userInfo = {};
            },
            save(){
                this.loading = true

                let selectedMenus = this.$refs.tree.getCheckedAndIndeterminateNodes();
                let uuids = [];
                selectedMenus.forEach((value) => {
                    uuids.push(value.uuid);
                })
                let data = {
                    uuid: this.userInfo.uuid,
                    roleUuids: uuids
                }
                this.post("/permission/user/role/update", data).then(() => {
                    this.$Message.info({
                        content: '设置成功'
                    });
                    this.$parent.roleInfo = {};
                    this.$parent.loadData();
                    this.loading = false;
                    this.cancel()
                }).catch(() => {
                    this.loading = false
                })
            }
        },
        watch:{
            updateUserRoleShow(){
                this.show = this.updateUserRoleShow;
            },
            show(){
                if(!this.show){
                    this.$parent.updateUserRoleShow = this.show;
                } else {
                    this.post("/permission/role/list", {}).then((value) => {
                        let data = value.data.data
                        for (let i = 0; i < data.length; i++) {
                            let o = data[i];
                            o['title'] = o.roleName;
                        }
                        this.post("/permission/user/role/list", {uuid: this.userInfo.uuid}).then(value => {
                            let uuidMap = [];
                            value.data.data.forEach(p => {
                                uuidMap[p.roleUuid] = p;
                            })
                            data.forEach(value1 => {
                                if(uuidMap[value1.uuid]){
                                    value1["checked"] = true;
                                }
                            });

                            this.roleInfo = data;
                        });
                    }).catch(() => {
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>