<template>
    <div>
        <Poptip trigger="click" placement="bottom-start">
            <div class="input_box">
                <div class="input_tag_box"  v-if="treeId.length > 0">
                    <Tag v-for="(item, index) in selectNode" v-bind:key="index" closable @on-close="handleCloseTag(index)">{{item.title}}</Tag>
                </div>
                <Input v-else :placeholder="placeholder"></Input>
            </div>
            <div slot="content" class="org-dropdown">
                <div class="org-tree">
                    <el-tree ref="treeRef" :data="tree" default-expand-all node-key="uuid" :expand-on-click-node="false" @node-click="selectTree"> </el-tree>
                </div>
            </div>
        </Poptip>
    </div>
</template>

<script>
    export default {
        name: "SelectTree",
        props: {
            value: {
                type: Array,
                default() {
                    return [];
                }
            },
            tree: {
                type: Array,
                default () {
                    return [];
                }
            },
            placeholder: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                treeId: []
            }
        },
        computed: {
            selectNode() {
                let arr = []
                let idMap = {}
                this.treeId.forEach(id => {
                    idMap[id] = id
                })
                let loop = (arrChild) => {
                    arrChild.forEach(node => {
                        if(idMap[node.uuid]){
                            arr.push(node);
                        }
                        if(node.children && node.children.length > 0){
                            loop(node.children)
                        }
                    })
                }
                loop(this.tree)

                this.$nextTick(() => {
                    this.$refs.treeRef.setCheckedKeys(this.treeId)
                })
                return arr
            }
        },
        watch: {
            value (val) {
                this.treeId = val;
            }
        },
        methods: {
            handleCloseTag(index){
                this.treeId.splice(index, 1)
            },
            selectTree: function (arr, node) {
                console.log(node)
                let reList = this.treeId.filter(value => value === node.data.uuid);
                if(reList.length == 0){
                    this.treeId.push(node.data.uuid)
                    this.$emit("input", this.treeId)
                }
            }
        }
    }
</script>

<style scoped>
    /deep/ .ivu-poptip{
        width: 100%;
    }
    /deep/ .ivu-poptip-rel{
        width: 100%;
    }
    .input_box{
        display: inline-block;
        width: 100%;
        line-height: 1.5;
        font-size: 14px;
        min-height: 32px;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        color: #515a6e;
        background-color: #fff;
        background-image: none;
        position: relative;
        cursor: text;
        transition: border .2s ease-in-out,background .2s ease-in-out,box-shadow .2s ease-in-out;
    }
    .input_tag_box{
        padding: 4px 7px;
    }
</style>